import 'bootstrap';
import 'owl.carousel';

var mobile = require('is-mobile');

$(document).ready(function(){
    $('.owl-carousel').owlCarousel({
        loop:true,
        margin:0,
        nav:true,
        items:1
    })

    $('.sub-nav').on('click',function (e){
        e.preventDefault();

        if ($(this).next('.hh-megamenu').hasClass('active')) {

            $(this).children('i').removeClass('hh-rotate-180');
            $(this).next('.hh-megamenu').removeClass('active');
        }
        else {
            $('.hh-megamenu').removeClass('active');
            $('.sub-nav i').removeClass('hh-rotate-180');
            $(this).children('i').addClass('hh-rotate-180');
            $(this).next('.hh-megamenu').addClass('active');
        }

    });

    $(window).click(function() {
        $('.hh-megamenu').removeClass('active');
        $('.sub-nav i').removeClass('hh-rotate-180');
    });

    $('#navbarNavDropdown, .hh-megamenu').click(function(e){
        e.stopPropagation();
    });
});